/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function NewSmsIcon({customSize = 32, color = '#2C305E'}) {
	return (
		<svg width={`${customSize}px`} height={`${customSize}px`} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="16" cy="16" r="16" fill="#F3F3F5"/>
			<g clipPath="url(#clip0_22479_28821)">
				<path fillRule="evenodd" clipRule="evenodd" d="M21.7711 8.79987H10.2587C9.46296 8.79987 8.7998 9.46302 8.7998 10.2588V18.2739C8.7998 19.0697 9.46296 19.7329 10.2587 19.7329H11.0545V22.5181C11.0545 22.9956 11.6116 23.2608 11.9829 22.969L15.9353 19.7594H21.7976C22.5934 19.7594 23.2566 19.0962 23.2566 18.3004V10.2588C23.23 9.43649 22.5934 8.79987 21.7711 8.79987ZM12.7998 15.1999C13.2416 15.1999 13.5998 14.8417 13.5998 14.3999C13.5998 13.958 13.2416 13.5999 12.7998 13.5999C12.358 13.5999 11.9998 13.958 11.9998 14.3999C11.9998 14.8417 12.358 15.1999 12.7998 15.1999ZM16.7998 14.3999C16.7998 14.8417 16.4416 15.1999 15.9998 15.1999C15.558 15.1999 15.1998 14.8417 15.1998 14.3999C15.1998 13.958 15.558 13.5999 15.9998 13.5999C16.4416 13.5999 16.7998 13.958 16.7998 14.3999ZM19.1998 15.1999C19.6416 15.1999 19.9998 14.8417 19.9998 14.3999C19.9998 13.958 19.6416 13.5999 19.1998 13.5999C18.758 13.5999 18.3998 13.958 18.3998 14.3999C18.3998 14.8417 18.758 15.1999 19.1998 15.1999Z" fill={`${color}`} />
			</g>
			<defs>
				<clipPath id="clip0_22479_28821">
					<rect width="16" height="16" fill="white" transform="translate(8 8)"/>
				</clipPath>
			</defs>
		</svg>
	);
}

NewSmsIcon.propTypes = {
	customSize: PropTypes.number,
	color: PropTypes.string,
};

export default NewSmsIcon;

