/* eslint-disable max-len */
import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {useTheme} from 'styled-components';
import {Icon} from '@phonectas/unifon-ui-kit';

/**
 * @param {{commentslength : number}} props
 * @return {ReactNode}
 */
function CommentsIcon(props) {
	const {commentslength} = props;

	const theme = useTheme();

	const getTransform = useCallback(() => {
		if (commentslength === typeof 'string') {
			return 'translate(8,2)';
		}
		if (commentslength?.toString().length <= 1) {
			return 'translate(8,2)';
		} else {
			return 'translate(6,2) scale(1.2, 1.05)';
		}
	}, [commentslength]);

	return (
		<Icon {...props}>
			<svg width="24" height="24" viewBox="6 0 20 20" xmlns="http://www.w3.org/2000/svg">
				{ }
				<path transform={getTransform()} d="M0 2C0 0.89543 0.895431 0 2 0H13C14.1046 0 15 0.895431 15 2V15V16.5858C15 17.4767 13.9229 17.9229 13.2929 17.2929L11.5858 15.5858C11.2107 15.2107 10.702 15 10.1716 15H2C0.895431 15 0 14.1046 0 13V2Z"/>
				<text
					fill={theme.palette.white}
					fontSize={16}
					textAnchor="middle"
					x="15"
					y="12.5"
				>
					{commentslength}
				</text>
			</svg>
		</Icon>

	);
}

CommentsIcon.propTypes = {
	commentslength: PropTypes.number.isRequired,
};

export default React.memo(CommentsIcon);
