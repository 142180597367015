import React, {useState, useEffect, useCallback, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import useThreeSixtyStore from 'common/stores/ThreeSixty';

import {Button, CloseIcon, Divider, IconButton, Layout, ListItem, SearchIcon, Select, Text} from '@phonectas/unifon-ui-kit';
import Events from './components/Events';
import SearchWidget from 'common/components/SearchWidget';
import Portal from 'common/components/Portal';
import CommentEvent from './components/CommentEvent';
import {StyledGrowLayout} from './styled';
import {LIMIT_PAYLOAD} from 'common/constants';
import {debounce} from 'lodash';

function CustomerEvents({customerId}) {
	const [t] = useTranslation();
	const {fetchAccountFeed} = useThreeSixtyStore();
	const accountFeed = useThreeSixtyStore((state) => state?.accountFeed);
	const activeAccount = useThreeSixtyStore((state) => state?.activeAccount);
	const [filter, setFilter] = useState('');
	const [currentLimit, setCurrentLimit] = useState(LIMIT_PAYLOAD);
	const [searchQuery, setSearchQuery] = useState('');
	const [showSearch, setShowSearch] = useState(false);
	const hasFetched = useRef(false);
	// add filter to debounce later
	const debouncedFetchList = useMemo(() => debounce((searchValue) => {
		fetchAccountFeed(activeAccount?.id, '', LIMIT_PAYLOAD, '', searchValue);
	}, 600), [fetchAccountFeed, activeAccount?.id]);
	const handleSearch = useCallback((query) => {
		if (typeof query === 'string') {
			setCurrentLimit(LIMIT_PAYLOAD);
			setSearchQuery(query);
			debouncedFetchList(query);
		} else {
			setSearchQuery('');
		}
	}, [debouncedFetchList]);
	const handleSearchClose = useCallback(() => {
		setSearchQuery('');
		setShowSearch(false);
		setCurrentLimit(LIMIT_PAYLOAD);
		if (activeAccount?.id) {
			fetchAccountFeed(activeAccount?.id, filter, LIMIT_PAYLOAD);
		}
	}, [filter, activeAccount?.id, fetchAccountFeed]);
	const onShowSearchBar = useCallback(() => setShowSearch(true), []);

	useEffect(() => {
		if (accountFeed?.total_count > currentLimit) {
			return;
		}
		if (activeAccount?.id && !hasFetched.current) {
			fetchAccountFeed(activeAccount?.id, filter, currentLimit, '', searchQuery);
			hasFetched.current = true;
		}
	}, [
		searchQuery,
		currentLimit,
		filter,
		fetchAccountFeed,
		activeAccount?.id,
		accountFeed?.total_count,
	]);

	useEffect(() => {
		hasFetched.current = false;
	}, [activeAccount]);

	const handleScroll = useCallback(() => {
		const scrollElement = document.getElementById('events_wrapper');
		if (scrollElement) {
			const {scrollTop, scrollHeight, clientHeight} = scrollElement;
			if (scrollTop + clientHeight >= scrollHeight - 1) {
				if (accountFeed?.total_count > currentLimit) {
					setCurrentLimit((prevLimit) => prevLimit + LIMIT_PAYLOAD);
				}
			}
		}
	}, [accountFeed?.total_count, currentLimit]);

	useEffect(() => {
		const scrollElement = document.getElementById('events_wrapper');
		if (scrollElement) {
			scrollElement.addEventListener('scroll', handleScroll);
		}
		return () => {
			if (scrollElement) {
				scrollElement.removeEventListener('scroll', handleScroll);
			}
		};
	}, [handleScroll]);

	useEffect(() => {
		if (accountFeed?.total_count > currentLimit) {
			fetchAccountFeed(activeAccount?.id, filter, currentLimit, '', searchQuery);
		}
	}, [accountFeed?.total_count, currentLimit, activeAccount?.id, filter, fetchAccountFeed, searchQuery]);

	const handleFilterChange = useCallback((value) => {
		setFilter(value);
		setCurrentLimit(LIMIT_PAYLOAD);
		fetchAccountFeed(activeAccount?.id, value, currentLimit);
	}, [activeAccount?.id, currentLimit, fetchAccountFeed, setFilter]);

	useEffect(() => {
		if (customerId !== activeAccount?.id) {
			handleSearchClose();
			fetchAccountFeed(customerId);
		}
	}, [customerId, handleSearchClose, fetchAccountFeed, activeAccount?.id]);

	return (
		<StyledGrowLayout direction="column" gap="none" nowrap fullHeight fullWidth style={{padding: '0px'}}>
			<Layout fullWidth alignItems="center" gap="xs" nowrap>
				<CommentEvent activeCustomer={activeAccount?.id} />
			</Layout>
			<Layout
				direction="row"
				nowrap
				justifyContent="space-between"
				fullWidth
				style={{height: '46px'}}
				alignItems="center"
			>
				<div id="search_Events_Portal" />
				<Portal portalId="search_Events_Portal">
					<Layout nowrap alignItems="center">
						{
							showSearch ?
								(
									<>
										<SearchWidget
											onChange={handleSearch}
											placeholder={t('Search')}
											openSearch={setShowSearch}
											clearSearch={handleSearchClose}
											autoFocus
										/>
										<div>
											<IconButton onClick={handleSearchClose}>
												<CloseIcon />
											</IconButton>
										</div>
									</>
								) :
								(
									<Button style={{paddingLeft: '0px'}} onClick={onShowSearchBar} icon={<SearchIcon />} compact>
										{t('Search')}
									</Button>
								)
						}
					</Layout>
				</Portal>
				<Layout alignItems="center" gap="xs" nowrap>
					<Text variant="body1" color="textSecondary">{t('Type')}</Text>
					<Select
						value={filter}
						onChange={handleFilterChange}
						alt
					>
						<ListItem value="" primaryText={t('All')} />
						<ListItem value="BN_CALL_LOG" primaryText={t('Call')} />
						{/* <ListItem value="ERATE_CALL_LOG" primaryText={t('SMS')} /> */}
						<ListItem value="ERATE_CALL_LOG" primaryText={t('Erate call')} />
						<ListItem value="ERATE_SYSTEM_SMS" primaryText={t('Erate SMS')} />
						{/* <ListItem value="rator" primaryText={t('Rator')} /> */}
						<ListItem value="THREESIXTY_COMMENT" primaryText={t('360 Comments')} />
						<ListItem value="THREESIXTY_NEW_CUSTOMER_RESP" primaryText={t('360 Resp')} />
						<ListItem value="THREESIXTY_NEW_SAF_VALUE" primaryText={t('360 SAF')} />
					</Select>
				</Layout>
			</Layout>
			<Divider style={{marginBottom: '15px'}}/>
			<Layout
				direction="row"
				fullHeight
				nowrap
				fullWidth
				justifyContent="space-between"
				style={{overflow: 'hidden'}}
			>
				<Events handleScrollAction={handleScroll} events={accountFeed}/>
			</Layout>
		</StyledGrowLayout>
	);
}

CustomerEvents.propTypes = {
	customerId: PropTypes.string,
};

export default CustomerEvents;
