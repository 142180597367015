import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import {IconButton, Layout, Tabs} from '@phonectas/unifon-ui-kit';
import CustomerEvents from './CustomerEvents';
import SmsModule from './SmsModule';
import {StyledEventLayout, StyledTabsLayout} from './styled';
import {useSmallScreen} from 'common/hooks/useScreenSize';
import EnlargeIcon from 'common/components/icons/EnlargeIcon';
import ShrinkIcon from 'common/components/icons/ShrinkIcon';

function EventsTab({expEvent, onShowExpand, onHideExpand, customerId}) {
	const [t] = useTranslation();
	const [activeTab, setActiveTab] = useState('1');
	const isSmallScreen = useSmallScreen('(max-width: 1600px)');
	// const isSmallScreenHeight = useSmallScreen('(max-height: 800px)');
	useEffect(() => {
		if (!isSmallScreen) {
			onHideExpand();
		}
	}, [isSmallScreen, onHideExpand]);

	return (
		<>
			<Layout fullWidth fullHeight style={{maxHeight: '40px'}}>
				<StyledTabsLayout direction="row" justifyContent="space-between" fullWidth>
					<Tabs
						active={activeTab}
						onChange={setActiveTab}
						items={[
							{value: '1', label: t('Event log')},
							{value: '2', label: t('SMS')},
						]}
					/>
					{
						isSmallScreen && !expEvent && (
							<IconButton size={26} onClick={onShowExpand}>
								<EnlargeIcon color="#2c305e" />
							</IconButton>
						)
					}
					{
						expEvent && isSmallScreen && (
							<IconButton size={26} onClick={onHideExpand}>
								<ShrinkIcon color="#2c305e" />
							</IconButton>
						)
					}
				</StyledTabsLayout>
			</Layout>
			<StyledEventLayout activeTab={activeTab}>
				{activeTab === '1' && <CustomerEvents customerId={customerId}/>}
				{activeTab === '2' && <SmsModule />}
			</StyledEventLayout>
		</>
	);
}

EventsTab.propTypes = {
	expEvent: PropTypes.bool,
	onShowExpand: PropTypes.func,
	onHideExpand: PropTypes.func,
	customerId: PropTypes.string,
};

export default EventsTab;
