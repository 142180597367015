import React, {useCallback} from 'react';
// import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import getIconByType from '../../../getIconByType';
import getTitleByType from '../../../getTitleByType';
import {getDateByCustomFormat, DATE_FORMATS} from 'utils/date';
import {IconButton, Layout, Text, TrashBinIcon, WarningIcon} from '@phonectas/unifon-ui-kit';
import EventDescription from '../EventDescription';
// import NewEllipseIcon from 'common/components/icons/newEllipseIcon';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
// import NewWindowIcon from 'common/components/icons/NewWindowIcon';

function ThreeSixtyComments({data, id}) {
	// const [t] = useTranslation();
	const {deleteEventComment, fetchAccountFeed} = useThreeSixtyStore();
	const currentUser = useThreeSixtyStore((state) => state.currentUser);
	const commentUser = data?.data_fields?.actor?.name || data?.data_fields?.actor_name;
	const isWarning = data?.is_warning;
	const handleDelete = useCallback(() => {
		deleteEventComment(id, () => {
			fetchAccountFeed(data.account_id);
		});
	}, [id, data.account_id, deleteEventComment, fetchAccountFeed]);

	return (
		<Layout gap="sm" fullWidth nowrap fullHeight>
			<Layout direction="column" nowrap gap="md" alignItems="center" style={{width: '42px'}}>
				<Layout>
					{getIconByType(data.feed_type)}
				</Layout>
				<Layout>
					{
						isWarning && (
							<Layout>
								<WarningIcon color="warning" />
							</Layout>
						)
					}
				</Layout>
			</Layout>
			<Layout direction="column" fullWidth nowrap>
				<Layout direction="row" fullWidth nowrap>
					<Layout direction="column" fullWidth nowrap gap="xs">
						<Layout direction="row" fullWidth alignItems="center" justifyContent="space-between">
							<Text variant="h3">{getTitleByType(data.feed_type)}</Text>
							<Text color="textSecondary" variant="body1">
								{
									getDateByCustomFormat(data.created_at, DATE_FORMATS.DD_MM_YY_HH_mm)
								}
							</Text>
						</Layout>
						<Layout fullWidth direction="row" nowrap>
							<Layout fullWidth gap="none">
								<Layout
									direction="row"
									justifyContent="space-between"
									fullWidth
									alignItems="center"
									gap="none"
								>
									<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
										{data?.data_fields?.actor?.name ? data.data_fields.actor?.name : data.data_fields?.actor_name}
									</Text>
									<IconButton
										size="sm"
										color="error"
										onClick={handleDelete}
										disabled={currentUser !== commentUser}
									>
										<TrashBinIcon />
									</IconButton>
								</Layout>
								<Layout
									direction="row"
									fullWidth
									justifyContent="space-between"
									gap="none"
									alignItems="center"
									nowrap
								>
									<Text color="textSecondary" variant="body1">
										{data?.data_fields.user_name}
									</Text>
								</Layout>
							</Layout>
						</Layout>
						<Layout direction="row" nowrap>
							<EventDescription description={data?.data_fields.body} />
						</Layout>
						<Layout direction="row" fullWidth style={{gap: '8px'}}>
							{/* <Layout gap="none" nowrap alignItems="center">
								<Text
									style={{userSelect: 'none'}}
									variant="body1"
									color="textSecondary"
								>
													Zendesk
								</Text>
								<IconButton size="sm" onClick={() => console.log('open zendesk')}>
									<NewWindowIcon color="#9798A9" />
								</IconButton>
							</Layout> */}
							{/* <div style={{alignItems: 'center', display: 'flex'}}><NewEllipseIcon /></div>
							<Layout gap="none" alignItems="center" nowrap>
								<Text variant="body1" color="textSecondary">{`ID ${data.id}`}</Text>
							</Layout> */}
						</Layout>
					</Layout>
				</Layout>
			</Layout>
		</Layout>
	);
}

ThreeSixtyComments.propTypes = {
	data: PropTypes.object,
	id: PropTypes.string,
};

export default ThreeSixtyComments;
