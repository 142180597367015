import React from 'react';
// import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';

import getIconByType from '../../../getIconByType';
import getTitleByType from '../../../getTitleByType';
// import {phoneNumber} from 'utils/number';
import {
	getDateByCustomFormat,
	DATE_FORMATS,
	// formatDuration,
} from 'utils/date';
// import {StyledDurationDiv} from '../styled';
import {Layout, Text, WarningIcon} from '@phonectas/unifon-ui-kit';
import EventDescription from '../EventDescription';
// import NewWindowIcon from 'common/components/icons/NewWindowIcon';

function ErateSystemCall({data}) {
	// const [t] = useTranslation();
	const isWarning = data?.is_warning;

	return (
		<Layout gap="sm" fullWidth nowrap fullHeight>
			<Layout direction="column" nowrap gap="md" alignItems="center" style={{width: '42px'}}>
				<Layout>
					{getIconByType(data.feed_type)}
				</Layout>
				<Layout>
					{
						isWarning && (
							<Layout>
								<WarningIcon color="warning" />
							</Layout>
						)
					}
				</Layout>
			</Layout>
			<Layout direction="column" fullWidth nowrap>
				<Layout direction="row" gap="xs" fullWidth nowrap>
					<Layout direction="column" fullWidth nowrap gap="xs">
						<Layout direction="row" fullWidth alignItems="center" gap="xs" justifyContent="space-between">
							<Text variant="h3">{getTitleByType(data.feed_type)}</Text>
							<Layout direction="row" nowrap justifyContent="space-between" fullWidth>
								<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
									{data?.data_fields?.sub_category ? data.data_fields.sub_category : ''}
								</Text>
								<Text color="textSecondary" variant="body1">
									{
										getDateByCustomFormat(data.created_at, DATE_FORMATS.DD_MM_YY_HH_mm)
									}
								</Text>
							</Layout>
						</Layout>
						<Layout fullWidth direction="row" nowrap>
							<Layout fullWidth gap="none">
								<Layout
									direction="row"
									justifyContent="space-between"
									fullWidth
									alignItems="center"
									gap="none"
								>
									<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
										{data?.data_fields?.main_category ? data.data_fields.main_category : ''}
									</Text>
								</Layout>
							</Layout>
						</Layout>
						<Layout direction="row" nowrap>
							<EventDescription description={data?.data_fields.comments} />
						</Layout>
						<Layout direction="row" fullWidth style={{gap: '8px'}}>
							{/* <Layout gap="none" nowrap alignItems="center">
								<Text
									style={{userSelect: 'none'}}
									variant="body1"
									color="textSecondary"
								>
													Zendesk
								</Text>
								<IconButton size="sm" onClick={() => console.log('open zendesk')}>
									<NewWindowIcon color="#9798A9" />
								</IconButton>
							</Layout> */}
							{/* <div style={{alignItems: 'center', display: 'flex'}}><NewEllipseIcon /></div>
							<Layout gap="none" alignItems="center" nowrap>
								<Text variant="body1" color="textSecondary">{`ID ${data.id}`}</Text>
							</Layout> */}
						</Layout>
					</Layout>
				</Layout>
			</Layout>
		</Layout>
	);
}

ErateSystemCall.propTypes = {
	data: PropTypes.object,
};

export default ErateSystemCall;


// return (
// 	<Layout gap="sm" fullWidth nowrap fullHeight>
// 		<Layout direction="column" nowrap gap="md" alignItems="center" style={{width: '42px'}}>
// 			<Layout>
// 				{getIconByType(data.feed_type)}
// 			</Layout>
// 			<Layout>
// 				{
// 					isWarning && (
// 						<Layout>
// 							<WarningIcon color="warning" />
// 						</Layout>
// 					)
// 				}
// 			</Layout>
// 		</Layout>
// 		<Layout direction="column" fullWidth nowrap>
// 			<Layout direction="row" fullWidth nowrap>
// 				<Layout direction="column" fullWidth nowrap gap="xs">
// 					<Layout direction="row" fullWidth alignItems="center" justifyContent="space-between">
// 						<Text variant="h3">{getTitleByType(data.feed_type)}</Text>
// 						<Text color="textSecondary" variant="body1">
// 							{
// 								getDateByCustomFormat(data.created_at, DATE_FORMATS.DD_MMM_YYYY_HH_mm)
// 							}
// 						</Text>
// 					</Layout>
// 					<Layout fullWidth direction="row" nowrap>
// 						<Layout fullWidth gap="none">
// 							<Layout
// 								direction="row"
// 								justifyContent="space-between"
// 								fullWidth
// 								alignItems="center"
// 								gap="none"
// 							>
// 								<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
// 									{phoneNumber(data?.data_fields.main_category)}
// 								</Text>
// 								{/* <Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
// 									{phoneNumber(data?.data_fields.sub_category)}
// 								</Text> */}
// 								<Text color="textSecondary" style={{alignItems: 'center'}} variant="body1">
// 									{phoneNumber(data?.data_fields.receiver_number)}
// 								</Text>
// 								{/* <StyledDurationDiv>
// 									<Text variant="body1">{formatDuration(data.data_fields.calltime_ms)}</Text>
// 								</StyledDurationDiv> */}
// 							</Layout>
// 							<Layout
// 								direction="row"
// 								fullWidth
// 								justifyContent="space-between"
// 								gap="none"
// 								alignItems="center"
// 								nowrap
// 							>
// 								<Text color="textSecondary" variant="body1">{data?.data_fields.created_by}</Text>
// 							</Layout>
// 						</Layout>
// 						{/* <Text color="textSecondary" variant="body1">{data.contact}</Text> */}
// 					</Layout>
// 					{
// 						data?.data_fields?.body && (
// 							<Layout direction="row" nowrap>
// 								<EventDescription description={data?.data_fields.comments} />
// 							</Layout>
// 						)
// 					}
// 					<Layout direction="row" fullWidth style={{gap: '8px'}}>
// 						{/* <Layout gap="none" nowrap alignItems="center">
// 							<Text
// 								style={{userSelect: 'none'}}
// 								variant="body1"
// 								color="textSecondary"
// 							>
// 								Zendesk
// 							</Text>
// 							<IconButton size="sm" onClick={() => console.log('open zendesk')}>
// 								<NewWindowIcon color="#9798A9" />
// 							</IconButton>
// 						</Layout> */}
// 						<div style={{alignItems: 'center', display: 'flex'}}><NewEllipseIcon /></div>
// 						{
// 							// add when more info is available
// 						}
// 					</Layout>
// 				</Layout>
// 			</Layout>
// 		</Layout>
// 	</Layout>
// );
