import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

import SearchWidget from 'common/components/SearchWidget';
import {Button, CloseIcon, IconButton, Layout, Page, ReloadIcon, SearchIcon, Text, Tooltip} from '@phonectas/unifon-ui-kit';
import {
	StyledCustomerGrid,
	StyledEventsTab,
	StyledGridItemCustomer,
	StyledGridItemHeader,
	StyledGridItemRight,
	StyledGridItemTables,
	StyledSectionDiv,
} from './styled';
import CustomerDetails from './components/CustomerDetails';
import CustomerWarnings from './components/CustomerWarnings';
import EventsTab from './components/SmsAndEvents';
import CustomerTabsNav from './components/CustomerTabs/CustomerTabsNav';
import LoaderPortal from 'common/components/Loader/components/LoaderPortal';
import useThreeSixtyStore from 'common/stores/ThreeSixty';
import ThreeSixtySearchBar from '../../ThreeSixtySearchBar';
import Portal from 'common/components/Portal';
import CustomerTabs from './components/CustomerTabs/CustomerTabs';
import {LIMIT_PAYLOAD} from 'common/constants';
import useBoundingClientRect from 'common/hooks/useBoundingClientRect';
import {useSmallScreen} from 'common/hooks/useScreenSize';

function ThreeSixtyCustomer(props) {
	const [t] = useTranslation();
	const params = useParams();
	const isSmallScreen = useSmallScreen('(max-width:1600px)');
	const isSmallScreenHeight = useSmallScreen('(max-height: 800px)');
	const isLoading = useThreeSixtyStore((state) => state.isLoading);
	const {
		fetchAccountById,
		fetchBillingGroups,
		fetchContacts,
		fetchZendeskCases,
		// fetchAccountFeed,
		fetchUsers,
		fetchInvoices,
	} = useThreeSixtyStore();
	const {activeAccount, isTableLoading} = useThreeSixtyStore((state) => ({
		activeAccount: state.activeAccount,
		isTableLoading: state.accountTables.isLoadingTable,
	}));
	const [customerId, setCustomerId] = useState(params?.id);
	const [showSearch, setShowSearch] = useState(false);
	const [showTableSearch, setTableShowSearch] = useState(false);
	const [tableQuery, setTableQuery] = useState('');
	const [warnings, setWarnings] = useState(false);
	const [activeTab, setActiveTab] = useState('1');
	const [sectionRef, boundingRect] = useBoundingClientRect();
	const [expandEvent, setExpandEvent] = useState(false);
	const [userTableFilter, setUserTableFilter] = useState(true);
	const onHideExpand = useCallback(() => {
		setExpandEvent(false);
	}, []);
	const onShowExpand = useCallback(() => {
		setExpandEvent(true);
	}, []);
	const onSearchClick = useCallback(() => setShowSearch((prev) => !prev), []);
	const fetchFunctions = useMemo(() => ({
		'1': fetchUsers,
		'2': fetchBillingGroups,
		'3': fetchContacts,
		'4': fetchInvoices,
		'5': fetchZendeskCases,
		// '6': fetchBedriftsnett,
	}), [fetchUsers, fetchBillingGroups, fetchContacts, fetchZendeskCases, fetchInvoices]);
	const handleTableTabChange = useCallback((tab) => {
		setActiveTab(tab);
	}, []);
	const fetchFunction = fetchFunctions[activeTab];
	const onTableQueryChange = useCallback((text) => {
		if (!text) return;
		if (activeTab === '1') {
			setUserTableFilter(false);
			fetchFunction(LIMIT_PAYLOAD, customerId, text, '', false);
			setTableQuery(text);
			return;
		}
		fetchFunction(LIMIT_PAYLOAD, customerId, text);
		setTableQuery(text);
	}, [customerId, fetchFunction, setTableQuery, activeTab]);
	const onShowTableSearch = useCallback(() => setTableShowSearch(true), []);
	const onHideTableSearch = useCallback(() => {
		setTableShowSearch(false);
		setTableQuery('');
		// if the user is on the users tab, we want to reset the filter
		setUserTableFilter(true);
		fetchFunction(LIMIT_PAYLOAD, customerId);
	}, [customerId, fetchFunction]);

	const refreshTable = useCallback(() => {
		if (fetchFunction) {
			fetchFunction(LIMIT_PAYLOAD, customerId);
		}
	}, [fetchFunction, customerId]);
	const onTableClearSearch = useCallback(() => setTableQuery(''), []);
	const toggleWarnings = () => setWarnings(!warnings);

	useEffect(() => {
		fetchAccountById(customerId);
		// fetchAccountFeed(customerId);
	}, [customerId, fetchAccountById]);

	useEffect(() => {
		if (customerId !== params?.id) {
			setCustomerId(params?.id);
			setActiveTab('1');
			setUserTableFilter(true);
			onHideTableSearch();
		}
	}, [params?.id, customerId, setCustomerId, setActiveTab]);

	// for test only warnings not implemented
	const testWarnings = false;

	return (
		<Page>
			<StyledCustomerGrid>
				<StyledGridItemHeader>
					<Layout direction="row" alignItems="center" nowrap fullWidth>
						<Text style={{fontSize: '30px'}} variant="h1">Unifon 360</Text>
						<div id="search_Customer_Portal" />
						<Portal portalId="search_Customer_Portal">
							<Layout style={{width: '400px'}} nowrap alignItems="center">
								{
									showSearch ?
										(
											<>
												<ThreeSixtySearchBar />
												<div>
													<IconButton onClick={onSearchClick}>
														<CloseIcon />
													</IconButton>
												</div>
											</>
										) :
										(
											<Button onClick={onSearchClick} icon={<SearchIcon />} compact>
												{t('Search')}
											</Button>
										)
								}
							</Layout>
						</Portal>
						{
							testWarnings && (
								<button style={{width: '135px'}} onClick={toggleWarnings}>{`Warnings: ${warnings}`}</button>
							)
						}
					</Layout>
				</StyledGridItemHeader>
				<StyledGridItemCustomer>
					<StyledSectionDiv $noPadding>
						<CustomerDetails warnings={warnings} data={activeAccount} />
					</StyledSectionDiv>
				</StyledGridItemCustomer>
				<StyledGridItemTables>
					<StyledSectionDiv
						$noBg
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							height: '60px',
						}}
					>
						<CustomerTabsNav
							amount={0}
							activeTab={activeTab}
							setActiveTab={handleTableTabChange}
						/>
						<div id="search_Table_Portal" />
						<Portal portalId="search_Table_Portal">
							<Layout nowrap alignItems="center">
								{
									showTableSearch ?
										(
											<>
												<SearchWidget
													onChange={onTableQueryChange}
													placeholder={t('Search')}
													openSearch={onShowTableSearch}
													searchQuery={tableQuery}
													clearSearch={onTableClearSearch}
													autoFocus
												/>
												<div>
													<IconButton onClick={onHideTableSearch}>
														<CloseIcon />
													</IconButton>
												</div>
											</>
										) :
										(
											<>
												<Tooltip text={t('Force refresh of current tab')}>
													<IconButton
														size="xl"
														onClick={refreshTable}
													>
														<ReloadIcon />
													</IconButton>
												</Tooltip>
												<Button
													onClick={onShowTableSearch}
													icon={<SearchIcon />}
													compact
												>
													{t('Search')}
												</Button>
											</>
										)
								}
							</Layout>
						</Portal>
					</StyledSectionDiv>
					<CustomerTabs
						search={tableQuery}
						customerId={customerId}
						activeTab={activeTab}
						userTableFilter={userTableFilter}
						setUserTableFilter={setUserTableFilter}
					/>
				</StyledGridItemTables>
				<StyledGridItemRight>
					{
						warnings && (
							<CustomerWarnings warnings={warnings} />
						)
					}
					<StyledEventsTab
						$endDiv
						$fullHeight
						$isSmall={isSmallScreen}
						ref={sectionRef}
						expandEvent={expandEvent}
						style={{
							paddingBottom: expandEvent ? `${boundingRect?.top}px` : '0',
							minHeight: isSmallScreenHeight ? '100px' : undefined,
						}}
					>
						<EventsTab
							expEvent={expandEvent}
							onShowExpand={onShowExpand}
							onHideExpand={onHideExpand}
							customerId={customerId}
						/>
					</StyledEventsTab>
				</StyledGridItemRight>
			</StyledCustomerGrid>
			<LoaderPortal isLoading={isLoading || isTableLoading} />
		</Page>
	);
}

export default ThreeSixtyCustomer;
