import React from 'react';

import NewCallIcon from 'common/components/icons/NewCallIcon';
import NewEmailIcon from 'common/components/icons/NewEmailIcon';
import NewSmsIcon from 'common/components/icons/NewSmsIcon';
import RatorEventIcon from 'common/components/icons/RatorEventIcon';
import ThreeSixtyIcon from 'common/components/icons/ThreeSixtyIcon';
import {WarningIcon} from '@phonectas/unifon-ui-kit';
import CommentsIcon from 'common/components/icons/CommentsIcon';
// import NewCommentIcon from '../../../../../../common/components/icons/NewCommentIcon';
// import EmailIcon from '../../../../../../common/components/icons/EmailIcon';

function getIconByType(type) {
	switch (type) {
	case 'BN_CALL_LOG':
		return <NewCallIcon unifonColor/>;
	case 'email':
		return <NewEmailIcon />;
	case 'THREESIXTY_OUTGOING_SMS':
		return <NewSmsIcon />;
	case 'rator':
		return <RatorEventIcon />;
	case 'ERATE_SYSTEM_SMS':
		return <RatorEventIcon />;
	case 'ERATE_CALL_LOG':
		return <RatorEventIcon />;
	case 'THREESIXTY_NEW_CUSTOMER_RESP':
		return <ThreeSixtyIcon editSize="26"/>;
	case 'THREESIXTY_NEW_SAF_VALUE':
		return <ThreeSixtyIcon editSize="26"/>;
	case 'THREESIXTY_COMMENT':
		return <CommentsIcon commentslength="..."/>;
	default:
		return <WarningIcon />;
	}
}

export default getIconByType;
