import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import {useCopyToClipboard} from 'common/hooks/useCopyToClipboard';
// import {DATE_FORMATS, getDateByCustomFormat} from 'utils/date';
import {serviceStatuses, maskString} from 'utils/div';
import {EmailIcon, Layout, Table, Text, Tooltip, WarningFilledIcon} from '@phonectas/unifon-ui-kit';
import {nullCheck} from 'utils/div';
import {convertMB} from 'utils/file';
import {npsScrore} from 'utils/div';

function UsersTableRow({data, onClick, bnUser}) {
	// eslint-disable-next-line no-unused-vars
	const [isCopied, copyToClipboard] = useCopyToClipboard();
	const [isHovered, setIsHovered] = useState(false);
	const checkedPin = nullCheck(data?.pin);
	const checkedPuk = nullCheck(data?.puk);
	const handleCopyEmail = useCallback((e) => {
		e.stopPropagation();
		copyToClipboard(data?.email);
	}, [copyToClipboard, data?.email]);
	const handleCopyPin = useCallback((e) => {
		e.stopPropagation();
		copyToClipboard(`PIN: ${checkedPin} / PUK: ${checkedPuk}`);
	}, [copyToClipboard, checkedPin, checkedPuk]);
	const handleRowClick = useCallback(() => {
		onClick(data);
	}, [onClick, data]);

	// for test remove this when endpint is ready
	const randomNumber = Math.floor(Math.random() * 10);
	const showThisTest = false;
	const isWarning = typeof data?.note === 'string' && data.note.trim() !== '';

	const handleMouseEnter = () => setIsHovered(true);
	const handleMouseLeave = () => setIsHovered(false);

	return (
		<Table.Row
			onClick={handleRowClick}
			style={{backgroundColor: bnUser === data.id ? 'rgba(115, 65, 47, 0.2)' : undefined}}
		>
			<Table.Cell>{nullCheck(data?.name)}</Table.Cell>
			<Table.Cell>
				{
					isWarning ? (
						<Layout gap="xs" nowrap>
							<WarningFilledIcon size="xs" color="error" />
							<Text truncate={1}>{data.phone_number}</Text>
						</Layout>
					) : nullCheck(data?.phone_number)
				}
			</Table.Cell>
			<Table.Cell>
				<span
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
					}}
				>
					{data?.email && (
						<Tooltip
							text={data?.email}
							arrow
						>
							<span
								style={{cursor: 'pointer'}}
								onClick={handleCopyEmail}
							>
								<EmailIcon />
							</span>
						</Tooltip>
					)}
				</span>
			</Table.Cell>
			<Table.Cell>
				{
					serviceStatuses(data?.status)
				}
			</Table.Cell>
			<Table.Cell>{nullCheck(data?.sim_type)}</Table.Cell>
			<Table.Cell>{convertMB(data?.data_used).valueInString}</Table.Cell>
			<Table.Cell>{nullCheck(data?.subscription_type)}</Table.Cell>
			<Table.Cell
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={{cursor: 'pointer'}}
				onClick={handleCopyPin}
			>
				{
					data?.pin && data?.puk && (
						isHovered ? `${checkedPin} / ${checkedPuk}` : `${maskString(checkedPin.toString())} / ${maskString(checkedPuk.toString())}`
					)
				}
			</Table.Cell>
			<Table.Cell>
				{
					showThisTest ? (
						<Text style={{color: npsScrore(randomNumber).color}}>
							{npsScrore(randomNumber).scoreView}
						</Text>

					) : ''
				}
			</Table.Cell>
		</Table.Row>
	);
}

UsersTableRow.propTypes = {
	data: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	bnUser: PropTypes.string,
};

export default React.memo(UsersTableRow);
