import i18next from 'i18next';
import PropTypes from 'prop-types';

// waiting for more Types to be added from API
function getTitleByType(type, withIcon = false) {
	switch (type) {
	case 'BN_CALL_LOG':
		return i18next.t('BN Call');
	case 'email':
		return i18next.t('Email');
	case 'sms':
		return i18next.t('SMS');
	case 'rator':
		return i18next.t('Rator');
	case 'ERATE_SYSTEM_SMS':
		return i18next.t('ERATE System SMS');
	case 'ERATE_CALL_LOG':
		return i18next.t('ERATE System Call');
	case 'THREESIXTY_NEW_CUSTOMER_RESP':
		return i18next.t('360 - New customer responsible');
	case 'THREESIXTY_COMMENT':
		return i18next.t('360 - Comment');
	case 'THREESIXTY_NEW_SAF_VALUE':
		return i18next.t('360 - New SAF value');
	case 'THREESIXTY_OUTGOING_SMS':
		return i18next.t('360 - Outgoing SMS');
	default:
		return i18next.t('Unknown event');
	}
}

getTitleByType.propTypes = {
	type: PropTypes.string,
	withIcon: PropTypes.bool,
};

export default getTitleByType;
